import React, { useState, useEffect, useRef } from 'react'
import httpClient from '../../httpClient'
import { useSelector, useDispatch } from 'react-redux'
import './PopupwindowCreateActivityMobile.css'
import { PiCaretLeftThin } from "react-icons/pi"
import DatePicker from '../DatePicker'
import { activity_types } from '../../assets/Data'
import Slider from '../Slider'
import { getRoundedDateHalfHour } from '../helpers/FormatDate'
import { MdOutlineArrowDropDown } from "react-icons/md"
import FormDropdownPopup from './FormDropdownPopup'
import { activity_types_svgs } from "../../assets/Data"
import DropdownCustomerMobile from './DropdownCustomerMobile'
import PopupwindowCreateCustomerMobile from '../../components/mobile/PopupwindowCreateCustomerMobile'
import CircularProgress from '@mui/material/CircularProgress'
import AlertPopupMobile from './AlertPopupMobile'
import { MdMarkEmailUnread } from "react-icons/md"
import { showAlertPopupMobile } from '../helpers/NotificationHelpers'

function PopupwindowCreateActivityMobile({
  id, isActive, setIsActive, popupID, onClose, fetchActivities,
  myBrands, selectedActivity, editActivity, setEditActivity,
  createReport, setCreateReport, setLoading, selectedCustomerObject
}) {
  const [loadingSaveButton, setLoadingSaveButton] = useState(false)

  const [activityType, setActivityType] = useState("")
  const [dueDate, setDueDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [isDone, setIsDone] = useState(false)
  const [comment, setComment] = useState("")
  const [priority, setPriority] = useState(3)
  const [title, setTitle] = useState("")
  const [report, setReport] = useState("")
  const [assignedTo, setAssignedTo] = useState({ "id": null, "name": null })
  const [moveToUndecided, setMoveToUndecided] = useState(false)
  const [moveToLost, setMoveToLost] = useState(false)
  const [selectedSalesPipeActions, setSelectedSalesPipeActions] = useState({})
  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [customers, setCustomers] = useState([])
  const [dropdownPopupIsOpen, setDropdownPopupIsOpen] = useState(false)
  const [dropdownList, setDropdownList] = useState([])
  const [dropdownID, setdropdownID] = useState("")
  const [createNewActivity, setCreateNewActivity] = useState(false)
  const [newCustomerPopupIsActive, setNewCustomerPopupIsActive] = useState(false)

  const dispatch = useDispatch()
  const thisPopupContainerRef = useRef(null)
  const saveButtonRef = useRef(null)

  const refParentDiv = useRef(null)
  const reportDataRedux = useSelector(state => state.visitReport)
  const userInfo = useSelector(state => state.user.userInfo)
  const requester = userInfo.type
  const arrayOfActivityTypes = Array.from(Object.keys(activity_types_svgs))
  const myTeam = useSelector(state => state.user.myTeam)

  const fetchCustomers = async () => {
    const responseCustomers = await httpClient.get(process.env.REACT_APP_API_URL + "/api/users/get")
    setCustomers(responseCustomers.data)
  }

  useEffect(() => {
    fetchCustomers()
  }, [])

  useEffect(() => {
    if (selectedCustomerObject) {
      setSelectedCustomer(selectedCustomerObject)
    }
  }, [isActive])

  const handleClosePopup = () => {
    setIsActive(false)
    setEditActivity(false)
    setCreateReport(false)
    setCreateNewActivity(false)
    cleanUpState()
  }

  useEffect(() => {
    if (editActivity) {
      setSelectedCustomer(selectedActivity.customer_id)
      setAssignedTo({
        ...assignedTo,
        "id": selectedActivity.sales_rep_id.id,
        "name": selectedActivity.sales_rep_id.first_name,
      })
      setActivityType(selectedActivity.type)
      setDueDate(selectedActivity.planned_date)
      setToDate(selectedActivity.planned_to_date)
      setIsDone(selectedActivity.done)
      setPriority(selectedActivity.priority)
      setTitle(selectedActivity.title)
      setComment(selectedActivity.comment)

      const reportObj = {}
      selectedActivity.report.forEach(report => {
        reportObj[report.brandowner_id.id] = { "report": report.report }
      })
      setReport(reportObj)
    } else if (createReport) {
      setSelectedCustomer(selectedActivity.customer_id)
      setIsDone(selectedActivity.done)

      const reportObj = {}
      selectedActivity.report.forEach(report => {
        reportObj[report.brandowner_id.id] = { "report": report.report }
      })
      setReport(reportObj)
    } else {
      setSelectedCustomer("")
      setActivityType("")
      setDueDate(null)
      setToDate(null)
      setIsDone(false)
      setComment("")
      setPriority(3)
      setTitle("")
      setReport("")
      setAssignedTo({ "id": null, "name": null })
    }
  }, [editActivity, createReport])

  useEffect(() => {
    if (priority) {
      const allPriorityBoxes = document.querySelectorAll(".priority_option")
      const priorityBox = document.querySelector(`[data-priority="${priority}"]`)

      allPriorityBoxes.forEach(box => box.classList.remove("selected"))

      if (priorityBox) {
        priorityBox.classList.add("selected")
      }
    }
  }, [priority])

  useEffect(() => {

    if (!activityType | !selectedCustomer | editActivity) {
      return
    }

    switch (activityType) {
      case "Visit":
      case "Meeting":
        setTitle(`${activityType} at ${selectedCustomer.name || selectedCustomer.business_name}`)
        break
      case "Task":
      case "Note":
        setTitle(`${activityType} for ${selectedCustomer.name || selectedCustomer.business_name}`)
        break
      case "Phone call":
        setTitle(`${activityType} with ${selectedCustomer.name || selectedCustomer.business_name}`)
        break
      case "Email":
        setTitle(`${activityType} of ${selectedCustomer.name || selectedCustomer.business_name}`)
        break
      default:
        setTitle("")
    }

  }, [activityType, selectedCustomer])

  useEffect(() => {
    setMoveToLost(false)
    setMoveToUndecided(false)
  }, [isDone])

  useEffect(() => {
    if (dueDate && (!toDate || new Date(toDate) < new Date(dueDate))) {
      const newToDate = new Date(dueDate)
      
      if (["Visit", "Meeting"].includes(activityType)) {
        // For meetings and visits, add 1 hour
        newToDate.setMinutes(newToDate.getMinutes() + 60)
      } else {
        // For other activity types, add 30 minutes
        newToDate.setMinutes(newToDate.getMinutes() + 30)
      }
      
      setToDate(newToDate.toISOString())
    }
  }, [dueDate, activityType])

  const cleanUpState = () => {
    setActivityType("")
    setDueDate(null)
    setToDate(null)
    setIsDone(false)
    setComment("")
    setPriority(3)
    setTitle("")
    setSelectedCustomer({})
    setReport("")
    setMoveToLost(false)
    setMoveToUndecided(false)
    setSelectedSalesPipeActions({})
    setEditActivity(false)
    setCreateNewActivity(false)
    setCreateReport(false)
    setAssignedTo({ "id": null, "name": null })
    const allPriorityBoxes = document.querySelectorAll(".priority_option")
    allPriorityBoxes.forEach(box => box.classList.remove("selected"))
  }

  const handleSaveActivity = async () => {

    const saveButton = document.querySelector('#save-btn-create-activity')
    saveButton.disabled = true
    setLoadingSaveButton(true)

    let response

    const selectedCustomerID = selectedCustomer.id

    let data = {
      activityType, dueDate, toDate, isDone,
      comment, priority, title, report,
      moveToLost, moveToUndecided,
      selectedCustomerID, assignedTo
    }

    if (!createReport & (activityType == "" || selectedCustomerID == "" || selectedCustomerID == null || !dueDate)) {
      
      showAlertPopupMobile({
        type: "error",
        message: "Required data missing. Please complete all fields."
      })

      saveButton.disabled = false
      setLoadingSaveButton(false)
      return
    }

    // Check if toDate is before dueDate
    if (toDate && dueDate && new Date(toDate) < new Date(dueDate)) {
      showAlertPopupMobile({
        type: "error",
        message: "End date cannot be before start date."
      })

      saveButton.disabled = false
      setLoadingSaveButton(false)
      return
    }

    if (editActivity) {
      response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/activities/update?activityID=${selectedActivity.id}`, data)
    } else if (createReport) {
      let dataReport = {
        "selectedActivityID": selectedActivity.id,
        selectedCustomerID,
        report
      }
      response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/activities/create-report", dataReport)

      if (createNewActivity) {
        data.isDone = false
        response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/activities/create", data)
      }
    } else {
      response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/activities/create", data)
    }

    // Fetch activities and wait for it to complete
    saveButton.disabled = false
    setLoadingSaveButton(false)

    handleClosePopup()

    setLoading(true)
    await fetchActivities()
      .then(() => {
        setLoading(false)
      })
      .catch(error => {
        console.error('Error fetching activities:', error)
        setLoading(false)
      })

    // Check if the response contains an .ics file
    const contentType = response.headers['content-type']
    if (contentType && contentType.includes('text/calendar')) {
      const blob = new Blob([response.data], { type: 'text/calendar;charset=utf-8' })

      // Use FileReader to read the blob as a data URL
      const reader = new FileReader()
      reader.onload = function (event) {
        const downloadLink = document.createElement('a')
        downloadLink.href = event.target.result
        downloadLink.setAttribute('download', 'event.ics')
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
      }
      reader.readAsDataURL(blob)
    } else {
      console.log(response.data)
    }
  }

  const handleOnclickPriority = (event, number) => {
    const allPriorityBoxes = document.querySelectorAll(".priority_option")
    const priorityBox = event.currentTarget

    allPriorityBoxes.forEach(box => box.classList.remove("selected"))
    priorityBox.classList.add("selected")
    setPriority(number)
  }

  const handleClickSalesPipeActionBtn = (brand_id, type) => {
    const newReport = { ...report }

    if (type === "undecided") {
      if (!newReport[brand_id]) {
        // Initialize brand_id object if it doesn't exist
        newReport[brand_id] = {}
      }

      if (newReport[brand_id].hasOwnProperty("undecided")) {
        // Toggle "undecided" if it exists
        newReport[brand_id]["undecided"] = !newReport[brand_id]["undecided"]
      } else {
        // Initialize "undecided" if it doesn't exist
        newReport[brand_id]["undecided"] = true
      }

      // Reset 'lost' to false if it exists
      if (newReport[brand_id]["lost"]) {
        newReport[brand_id]["lost"] = false
      }
    }

    if (type === "lost") {
      if (!newReport[brand_id]) {
        // Initialize brand_id object if it doesn't exist
        newReport[brand_id] = {}
      }

      if (newReport[brand_id].hasOwnProperty("lost")) {
        // Toggle "lost" if it exists
        newReport[brand_id]["lost"] = !newReport[brand_id]["lost"]
      } else {
        // Initialize "lost" if it doesn't exist
        newReport[brand_id]["lost"] = true
      }

      // Reset 'undecided" to false if it exists
      if (newReport[brand_id]["undecided"]) {
        newReport[brand_id]["undecided"] = false
      }
    }

    setReport(newReport)

    // Update the selected actions state
    setSelectedSalesPipeActions(prev => {
      // Deselect if the same button is clicked again
      if (prev[brand_id] === type) {
        return { ...prev, [brand_id]: null };
      }
      return { ...prev, [brand_id]: type };
    })

  }

  const handleSetReport = (event, brand_id) => {
    const newReport = { ...report }
    newReport[brand_id] = { "report": event.currentTarget.value }
    setReport(newReport)
  }

  const handleCopyToAll = () => {
    const firstKeyReport = Object.keys(report)[0]
    const firstReport = report[firstKeyReport]
    const firstKeySelectedSalesPipeActions = Object.keys(selectedSalesPipeActions)[0]
    const firstSelectedSalesPipeActions = selectedSalesPipeActions[firstKeySelectedSalesPipeActions]

    const newReport = { ...report }
    const newSelectedSalesPipeActions = { ...selectedSalesPipeActions }

    myBrands.forEach(brand => {
      newReport[brand.id] = firstReport
      newSelectedSalesPipeActions[brand.id] = firstSelectedSalesPipeActions

    })

    setReport(newReport)
    setSelectedSalesPipeActions(newSelectedSalesPipeActions)
  }

  const handleDeleteActivity = async () => {
    const response = await httpClient.delete(process.env.REACT_APP_API_URL + `/api/activities/delete?activityID=${selectedActivity.id}`)

    handleClosePopup()
    
    // Fetch activities and wait for it to complete
    setLoading(true)
    await fetchActivities()
      .then(() => {
        setLoading(false)
      })
      .catch(error => {
        console.error('Error fetching activities:', error)
        setLoading(false)
      })

    // Check if the response contains an .ics file
    const contentType = response.headers['content-type']
    if (contentType && contentType.includes('text/calendar')) {
      const blob = new Blob([response.data], { type: 'text/calendar;charset=utf-8' })

      // Use FileReader to read the blob as a data URL
      const reader = new FileReader()
      reader.onload = function (event) {
        const downloadLink = document.createElement('a')
        downloadLink.href = event.target.result
        downloadLink.setAttribute('download', 'event.ics')
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
      }
      reader.readAsDataURL(blob)
    } else {
      console.log(response.data)
    }
  }

  const handleOnClickPopup = (e, type) => {

    switch (type) {
      case "activity-type":
        setDropdownList(arrayOfActivityTypes)
        setdropdownID("activity-type")
        setDropdownPopupIsOpen(true)
        break

      case "activity-assign-to":
        setDropdownList(myTeam)
        setdropdownID("activity-assign-to")
        setDropdownPopupIsOpen(true)
        break
    }
  }

  const handleSetActivityState = (id, value) => {
    switch (id) {
      case "activity-type":
        setActivityType(value)
        break
      case "activity-assign-to":
        setAssignedTo(value)
        break
    }
  }

  const handleDownloadEmail = () => {
    if (selectedActivity.email_log && selectedActivity.email_log.email_file_url) {
      window.open(selectedActivity.email_log.email_file_url, '_blank')
    }
  }

  return (
    <div className={`popup_window__container create-activity ${isActive ? "active" : ""}`} id={id} ref={thisPopupContainerRef}>

      <FormDropdownPopup
        id={dropdownID}
        list={dropdownList}
        isOpen={dropdownPopupIsOpen}
        setIsOpen={setDropdownPopupIsOpen}
        setValue={handleSetActivityState}
      />

      <PopupwindowCreateCustomerMobile
        fetchCustomers={fetchCustomers}
        isActive={newCustomerPopupIsActive}
        setIsActive={setNewCustomerPopupIsActive}
        setCustomer={setSelectedCustomer}
      />

      <div className='popup_header'>
        <div className='go_back' onClick={handleClosePopup}><PiCaretLeftThin /></div>
        <div className='header__buttons'>
          {
            editActivity ?
              <>
                <button className='red-button-header' id='delete-btn-create-activity' onClick={handleDeleteActivity}>
                  {
                    loadingSaveButton ?
                      <CircularProgress color="inherit" size={"1rem"} /> :
                      "Delete"
                  }
                </button>
                <button className='green-button-header' id='save-btn-create-activity' onClick={handleSaveActivity}>
                  {
                    loadingSaveButton ?
                      <CircularProgress color="inherit" size={"1rem"} /> :
                      "SAVE EDIT"
                  }
                </button>
              </> :
              <button className='green-button-header' id='save-btn-create-activity' onClick={handleSaveActivity} ref={saveButtonRef}>
                {
                  loadingSaveButton ?
                    <CircularProgress color="inherit" size={"1rem"} /> :
                    "SAVE"
                }
              </button>
          }
          {
            myBrands.length > 1 &&
            isDone &&
            <>
              <button className='form_option_button' id='copy-btn-create-activity' onClick={handleCopyToAll}>Copy to all</button>
            </>
          }

        </div>
      </div>
      <div className='popup_body' ref={refParentDiv}>
        {
          createReport ?
            <>
              {
                myBrands.map((brand, index) => (
                  <div key={index}>
                    <h1>Report for {brand.business_name}</h1>
                    <textarea className='input_text--mobile textarea' placeholder='Report' value={report[brand.id] ? report[brand.id]["report"] : ""} onChange={(event) => handleSetReport(event, brand.id)} maxLength={"1000"} />

                    <div className='action-buttons' id={`sales-pipe-buttons-${index}`}>
                      <button className={`gray-action-button selecting wdth100 hght100 ${selectedSalesPipeActions[brand.id] === 'undecided' ? 'selected' : ''}`} onClick={() => handleClickSalesPipeActionBtn(brand.id, "undecided")}>Move to undecided</button>
                      <button className={`red-action-button selecting wdth100 hght100 ${selectedSalesPipeActions[brand.id] === 'lost' ? 'selected' : ''}`} onClick={() => handleClickSalesPipeActionBtn(brand.id, "lost")}>Move to lost</button>
                    </div>
                  </div>
                ))
              }

              <div className='wdth75'>
                <Slider
                  state={createNewActivity}
                  onClickFunction={() => setCreateNewActivity(!createNewActivity)}
                  text={"Create next activity?"}
                  fontSize={"1rem"}
                  size={2}
                  theme="light--mobile"
                />
              </div>

              {
                createNewActivity &&
                <>
                  <h1>Create new activity</h1>

                  <DropdownCustomerMobile
                    // onClickItem={handleOnClickDropdownItemCustomer}
                    value={{ "id": selectedCustomer?.id, "name": selectedCustomer?.business_name }}
                    setValue={setSelectedCustomer}
                    createNewCustomer={setNewCustomerPopupIsActive}
                  />

                  <div className='form_dropdown--mobile' name='activity-type' onClick={(e) => handleOnClickPopup(e, "activity-type")}>
                    {
                      activityType ?
                        <p>{activityType}</p> :
                        <p className='placeholder'>Type</p>
                    }
                    <MdOutlineArrowDropDown />
                  </div>

                  {
                    ["Note"].includes(activityType) ?
                      null :
                      ["Visit", "Meeting"].includes(activityType) ?
                        <>
                          <DatePicker
                            date={dueDate}
                            setDate={setDueDate}
                            placeholder={"Planned from"}
                            enableTime={true}
                            altInput={true}
                            style="mobile"
                          />

                          <DatePicker
                            date={toDate}
                            setDate={setToDate}
                            placeholder={"Planned to"}
                            enableTime={true}
                            altInput={true}
                            style="mobile"
                          />
                        </> :
                        <>
                          <DatePicker
                            date={dueDate}
                            setDate={setDueDate}
                            placeholder={"Due date"}
                            enableTime={true}
                            altInput={true}
                            style="mobile"
                          />
                        </>
                  }

                  <input className='input_text--mobile' value={title} placeholder='Title' onChange={(event) => setTitle(event.currentTarget.value)} maxLength={"100"} />

                  <textarea className='input_text--mobile textarea' value={comment} placeholder='Comment' onChange={(event) => setComment(event.currentTarget.value)} maxLength={"1000"} />

                  {
                    !["Note"].includes(activityType) ?
                      <>
                        <div className='priority_container'>
                          <div className='priority_title important'>important</div>
                          <div className='priority_title not-important'>not important</div>
                          <div className='priority_title urgent'>urgent</div>
                          <div className='priority_title not-urgent'>not urgent</div>

                          <div className='priority_options'>
                            <div className='priority_option one' data-priority={1} onClick={(event) => handleOnclickPriority(event, 1)}>
                              <div className='priority_option_content'>
                                1
                              </div>
                            </div>
                            <div className='priority_option two' data-priority={2} onClick={(event) => handleOnclickPriority(event, 2)}>
                              <div className='priority_option_content'>
                                2
                              </div>
                            </div>
                            <div className='priority_option three' data-priority={3} onClick={(event) => handleOnclickPriority(event, 3)}>
                              <div className='priority_option_content'>
                                3
                              </div>
                            </div>
                            <div className='priority_option four' data-priority={4} onClick={(event) => handleOnclickPriority(event, 4)}>
                              <div className='priority_option_content'>
                                4
                              </div>
                            </div>
                          </div>
                        </div>
                      </> : null
                  }

                </>
              }

            </> :
            <>
              {
                editActivity ?
                  <h1>Edit activity</h1> :
                  <h1>Create new activity</h1>
              }
              {
                !["Note"].includes(activityType) &&
                <div className='wdth75'>
                  <Slider
                    state={isDone}
                    onClickFunction={() => setIsDone(!isDone)}
                    text={"Mark as done"}
                    fontSize={"1rem"}
                    size={2}
                    theme="light--mobile"
                  />
                </div>
              }

              <DropdownCustomerMobile
                // onClickItem={handleOnClickDropdownItemCustomer}
                value={{ "id": selectedCustomer?.id, "name": selectedCustomer?.business_name }}
                setValue={setSelectedCustomer}
                createNewCustomer={setNewCustomerPopupIsActive}
              />

              {
                ['manager'].includes(userInfo.type) &&
                <>
                  <div className='form_dropdown--mobile' name='activity-assign-to' onClick={(e) => handleOnClickPopup(e, "activity-assign-to")}>
                    {
                      assignedTo?.name ?
                        <p>{assignedTo.name}</p> :
                        <p className='placeholder'>Assign to</p>
                    }
                    <MdOutlineArrowDropDown />
                  </div>
                </>
              }

              <div className='form_dropdown--mobile' name='activity-type' onClick={(e) => handleOnClickPopup(e, "activity-type")}>
                {
                  activityType ?
                    <p>{activityType}</p> :
                    <p className='placeholder'>Type</p>
                }
                <MdOutlineArrowDropDown />
              </div>

              {
                ["Note"].includes(activityType) ?
                  null :
                  ["Visit", "Meeting"].includes(activityType) ?
                    <>
                      <DatePicker
                        date={dueDate}
                        setDate={setDueDate}
                        placeholder={"Planned from"}
                        enableTime={true}
                        altInput={true}
                        style="mobile"
                      />

                      <DatePicker
                        date={toDate}
                        setDate={setToDate}
                        placeholder={"Planned to"}
                        enableTime={true}
                        altInput={true}
                        style="mobile"
                      />
                    </> :
                    <>
                      <DatePicker
                        date={dueDate}
                        setDate={setDueDate}
                        placeholder={"Due date"}
                        enableTime={true}
                        altInput={true}
                        style="mobile"
                      />
                    </>
              }

              <input className='input_text--mobile' value={title} placeholder='Title' onChange={(event) => setTitle(event.currentTarget.value)} maxLength={"100"} />

              {
                ["Note"].includes(activityType) ?
                  <>
                    <textarea className='input_text--mobile textarea' value={comment} placeholder='Comment' onChange={(event) => setComment(event.currentTarget.value)} maxLength={"1000"} />
                  </> : isDone ?
                    <>
                      {["Email"].includes(activityType) && selectedActivity.email_log ? (
                        <div>
                          <h1>Email Message</h1>
                          <div className='email-message-container'>
                            <textarea
                              className='input_text--mobile textarea'
                              value={selectedActivity.email_log.body}
                              readOnly
                            />
                            <div
                              className='download-email-button'
                              onClick={handleDownloadEmail}
                              title="Download Email"
                            >
                              <MdMarkEmailUnread />
                              <span>Download Email</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        myBrands.map((brand, index) => (
                          <div key={index}>
                            <h1>Report for {brand.business_name}</h1>
                            <textarea
                              className='input_text--mobile textarea'
                              placeholder='Report'
                              value={report[brand.id] ? report[brand.id]["report"] : ""}
                              onChange={(event) => handleSetReport(event, brand.id)}
                              maxLength={"1000"}
                            />
                            <div className='action-buttons' id={`sales-pipe-buttons-${index}`}>
                              <button
                                className={`gray-action-button selecting wdth100 hght100 ${selectedSalesPipeActions[brand.id] === 'undecided' ? 'selected' : ''}`}
                                onClick={() => handleClickSalesPipeActionBtn(brand.id, "undecided")}
                              >
                                Move to undecided
                              </button>
                              <button
                                className={`red-action-button selecting wdth100 hght100 ${selectedSalesPipeActions[brand.id] === 'lost' ? 'selected' : ''}`}
                                onClick={() => handleClickSalesPipeActionBtn(brand.id, "lost")}
                              >
                                Move to lost
                              </button>
                            </div>
                          </div>
                        ))
                      )}
                    </> :
                    <>
                      <textarea className='input_text--mobile textarea' value={comment} placeholder='Comment' onChange={(event) => setComment(event.currentTarget.value)} maxLength={"1000"} />
                    </>
              }

              {
                !["Note"].includes(activityType) & !isDone ?
                  <>
                    <div className='priority_container'>
                      <div className='priority_title important'>important</div>
                      <div className='priority_title not-important'>not important</div>
                      <div className='priority_title urgent'>urgent</div>
                      <div className='priority_title not-urgent'>not urgent</div>

                      <div className='priority_options'>
                        <div className='priority_option one' data-priority={1} onClick={(event) => handleOnclickPriority(event, 1)}>
                          <div className='priority_option_content'>
                            1
                          </div>
                        </div>
                        <div className='priority_option two' data-priority={2} onClick={(event) => handleOnclickPriority(event, 2)}>
                          <div className='priority_option_content'>
                            2
                          </div>
                        </div>
                        <div className='priority_option three' data-priority={3} onClick={(event) => handleOnclickPriority(event, 3)}>
                          <div className='priority_option_content'>
                            3
                          </div>
                        </div>
                        <div className='priority_option four' data-priority={4} onClick={(event) => handleOnclickPriority(event, 4)}>
                          <div className='priority_option_content'>
                            4
                          </div>
                        </div>
                      </div>
                    </div>
                  </> : null
              }
            </>
        }
      </div>
    </div>
  )
}

export default PopupwindowCreateActivityMobile

PopupwindowCreateActivityMobile.defaultProps = {
  id: "popup-window-mobile,"
}