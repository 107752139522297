import React, { useState, useEffect, useRef, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import httpClient from "../httpClient"
import { useNavigate, useLocation, useBeforeUnload } from "react-router-dom"
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import { closePopupWindow, PopupWindow } from "../components/PopupWindow"
import { PopOutWindow } from "../components/PopOutWindow"
import CheckBoxStatic from "../components/CheckBoxStatic"
import { useParams } from 'react-router-dom'
import { NotificationTop, showNotification } from "../components/NotificationTop"
import { changeNotificationMessage } from "../store/notification"
import { updateSalesPipeNeedsRefresh } from "../store/salesPipePage"
import { fetchSuppliers } from '../store/userThunks'
import "./CustomerInfoPage.css"
import Label from "../components/Label"
import { ToastContainer, toast } from "react-toastify"
import ComingSoonOverlay from "../components/placeholders/ComingSoonOverlay"
import ActivityFiche from "../components/ActivityFiche"
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { formatDate } from "../components/helpers/FormatDate"
import { activity_types } from '../assets/Data'
import { PopOutWindowCreateActivity } from "../components/PopOutWindowCreateActivity"
import { PopOutWindowCreateActivityReport } from "../components/PopOutWindowCreateActivityReport"
import TableCheckmarkHeader from '../components/TableCheckmarkHeader'
import TableCheckmarkRow from '../components/TableCheckmarkRow'
import { NotificationPopupMessage, showNotificationPopupMessage } from "../components/NotificationPopupMessage"
import { PopOutWindowCreateOrder } from "../components/PopOutWindowCreateOrder"
import CheckBox from "../components/CheckBox"
import CheckBoxCustomDetails from "../components/CheckBoxCustomDetails"
import InputCustomDetails from "../components/InputCustomDetails"
import DropDownCustomDetails from "../components/DropDownCustomDetails"
import PopupWindowCreateNewCustomer from "../components/PopupWindowCreateNewCustomer"
import EditIcon from '@mui/icons-material/Edit'
import Dropdown from "../components/Dropdown"
import SearchBarList from "../components/SearchBarList"
import CircularProgress from '@mui/material/CircularProgress'
import Slider from "../components/Slider"
import {
  updateFilters,
  updateCustomerList,
  resetStateCustomerPage,
  updateTotalPagesCustomers,
  updateArrayOfCitiesCustomers
} from "../store/customerPage"
import { MdOutlineMarkEmailUnread } from "react-icons/md"
import PopupDropdown from "../components/PopupDropdown"
import { customerContactRhythmsArray } from "../assets/Data"
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import CustomerContentGrid from "../components/CustomerContentGrid"
import { IoClose } from "react-icons/io5"
import { TfiMicrosoftAlt } from "react-icons/tfi"
import { NotificationPopup, showNotificationPopup } from "../components/NotificationPopup"

function CustomerInfoPage() {

  const { userID } = useParams()
  const isCreateMode = userID === 'new'
  const initialCustomerState = {
    business_name: "",
    email: "",
    tel_number: "",
    first_name: "",
    last_name: "",
    company_name: "",
    sales_email: "",
    invoice_email: "",
    VAT_number: "",
    invoice_address_street: "",
    invoice_address_nr: "",
    invoice_address_pc: "",
    invoice_address_place: "",
    invoice_address_country: "Belgium",
    delivery_address_street: "",
    delivery_address_nr: "",
    delivery_address_pc: "",
    delivery_address_place: "",
    delivery_address_country: "Belgium",
    bio: "",
    is_supplier: false,
    create_in_business_central: true,
    type: "",
    sub_type: "",
  }

  const [customerID, setCustomerID] = useState(null)
  const [loading, setLoading] = useState(false)
  const [customer, setCustomer] = useState(initialCustomerState)
  const [loadingActivities, setLoadingActivities] = useState(false)
  const [loadingProductAvailability, setLoadingProductAvailability] = useState(true)
  const [loadingMaterialAvailability, setLoadingMaterialAvailability] = useState(true)
  const [loadingCustomDetails, setLoadingCustomDetails] = useState(false)
  const [loadingCustomerSupplier, setLoadingCustomerSupplier] = useState(false)
  const [loadingSaveButton, setLoadingSaveButton] = useState(false)
  const [error, setError] = useState(false)
  const [emailError, setEmailError] = useState("Not a valid email address")
  const [invoiceEmailError, setInvoiceEmailError] = useState("Not a valid email address")
  const [VATNumberError, setVATNumberError] = useState("VAT Number not in correct format BE0000.000.000")
  const [changesMade, setChangesMade] = useState(false)
  const [activities, setActivities] = useState([])
  const [myProductSuppliers, setMyProductSuppliers] = useState([])
  const [vendorPopupIsActive, setVendorPopupIsActive] = useState(false)
  const [vendors, setVendors] = useState([])
  const [popUpSuppliersProduct, setPopUpSuppliersProduct] = useState("")
  const [selectedProductID, setSelectedProductID] = useState()
  const [searchValueSupplier, setSearchValueSupplier] = useState("")
  const [typesDropdownIsOpen, setTypesDropdownIsOpen] = useState(false)
  const [subTypeDropdownIsOpen, setsubTypeDropdownIsOpen] = useState(false)
  const [menuItem, setMenuItem] = useState(isCreateMode ? "contact-details" : "")
  const [customDetails, setCustomDetails] = useState([])
  const [myBrands, setMyBrands] = useState([])
  const [newCustomerPopupIsActive, setNewCustomerPopupIsActive] = useState(false)
  const [selectedActivity, setSelectedActivity] = useState(null)
  const [selectedCustomer, setSelectedCustomer] = useState("")
  const [selectedCustomerID, setSelectedCustomerID] = useState("")
  const [selectedRows, setSelectedRows] = useState([])
  const [editActivity, setEditActivity] = useState(false)
  const [ownerOfClient, setOwnerOfClient] = useState("")
  const [ownerHistory, setOwnerHistory] = useState([])
  const [contactRhythm, setContactRhythm] = useState()
  const [availabilityState, setAvailabilityState] = useState({
    productsPage: 1,
    materialsPage: 1,
    limit: 25,
    productsHasMore: true,
    materialsHasMore: true,
    productsAvailability: [],
    materialsAvailability: [],
    loadingMoreProducts: false,
    loadingMoreMaterials: false,
  })
  const [availabilityChanges, setAvailabilityChanges] = useState([])
  const [availabilityFilters, setAvailabilityFilters] = useState({
    "searchWord": "",
    "hideUnavailable": false
  })
  const [customerSuppliers, setCustomerSuppliers] = useState([{
    supplier: null,
    alias: ''
  }])
  const [customerContentImages, setCustomerContentImages] = useState([])
  const [customerContentFiles, setCustomerContentFiles] = useState([])
  const [createActivity, setCreateActivity] = useState(false)
  const [createActivityReport, setCreateActivityReport] = useState(false)
  const [supplyingCustomers, setSupplyingCustomers] = useState([])

  const [businessNameDropdownIsActive, setBusinessNameDropdownIsActive] = useState(true)
  const [loadingBusinessNameDropdown, setLoadingBusinessNameDropdown] = useState(false)
  const [businessNameSelected, setBusinessNameSelected] = useState(false)
  const [googleBusinessSearchResults, setGoogleBusinessSearchResults] = useState([])
  const [debouncedBusinessName, setDebouncedBusinessName] = useState('')
  const [searchRequestLimitReached, setSearchRequestLimitReached] = useState(false)
  const [loadingInputBoxes, setLoadingInputBoxes] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  let key = 0
  let key2 = 1

  const userInfo = useSelector(state => state.user.userInfo)
  const customerTypesArray = useSelector(state => state.user.customer_types)
  const filters = useSelector(state => state.customerPage.filters)
  const requester = userInfo.type
  const arrayOfSubTypes = Object.values(customerTypesArray).flat(1)
  const arrayOfTypes = Object.keys(customerTypesArray)
  const now = new Date()
  const myTeam = useSelector(state => state.user.myTeam)
  const suppliers = useSelector(state => state.user.suppliers)
  const reportItems = useSelector(state => state.user.reportItems)

  const dropdownRefTypes = useRef(null)
  const parentDivRef = useRef(null)
  const dropdownRefSubTypes = useRef(null)
  const saveButtonRef = useRef(null)

  const fetchCustomer = async () => {
    setLoading(true)

    const responseCustomer = await httpClient.get(process.env.REACT_APP_API_URL + "/api/user/get?customerID=" + userID)

    setCustomer({
      ...initialCustomerState,
      ...responseCustomer.data
    })
    setCustomerID(responseCustomer.data.id)
    setSelectedCustomer(responseCustomer.data.business_name)
    setSelectedCustomerID(responseCustomer.data.id)

    if (responseCustomer.data.contact_rhythm) {
      setContactRhythm(responseCustomer.data.contact_rhythm.rhythm)
    }

    setLoading(false)
  }

  const fetchActivities = async () => {
    setLoadingActivities(true)
    const responseActivities = await httpClient.get(process.env.REACT_APP_API_URL + `/api/activities/get-all-activities-of-customer?customerID=${customerID}&requester=${requester}`)
    setActivities(responseActivities.data)
    setLoadingActivities(false)
  }

  const fetchMyProductSuppliers = async () => {
    const responseProducts = await httpClient.get(process.env.REACT_APP_API_URL + "/api/customer/get-suppliers-of-my-products?requester=" + requester + "&customerID=" + customerID + "&createMode=" + isCreateMode)
    setMyProductSuppliers(responseProducts.data)
  }

  const fetchVendors = async () => {
    const reponseVendors = await httpClient.get(process.env.REACT_APP_API_URL + "/api/customer/get-vendors?requester=" + requester + "&customerID=" + customerID)
    setVendors(reponseVendors.data)
  }

  const fetchProductAvailability = async () => {
    const data = {
      ...availabilityState,
      filters: availabilityFilters,
    }

    try {
      const response = await httpClient.post(
        `${process.env.REACT_APP_API_URL}/api/user/classification/product-availability/get?customerID=${customerID}`,
        data
      )
      const responseData = response.data

      // Update products/materials lists
      setAvailabilityState(prev => ({
        ...prev,
        productsAvailability: responseData.availabilities.products,
        materialsAvailability: responseData.availabilities.materials,
        productsHasMore: responseData.has_more_products,
        materialsHasMore: responseData.has_more_materials,
        loadingMoreProducts: false,
        loadingMoreMaterials: false
      }))
    } catch (error) {
      console.error('Error fetching product availability:', error)
    }
  }

  const fetchCustomCustomerDetails = async () => {
    setLoadingCustomDetails(true)

    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/user/classification/custom-user-details/get?customerID=" + customerID)
      setCustomDetails(response.data)
    } catch (error) {
      console.error("Error fetching custom customer details:", error)
    } finally {
      setLoadingCustomDetails(false)
    }
  }

  const fetchMyBrands = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/salesforce/get-my-brandowners`)

    setMyBrands(response.data)
  }

  const fetchCustomers = async () => {
    const requestData = {
      listType: filters.customersListType,
      filters: {
        city: filters.cityFilter,
        postal_code: filters.postalCodeFilter,
        type: filters.typeFilter,
        sub_type: filters.subTypeFilter,
        search_word: filters.searchWord,
        b2c: filters.filterB2C,
        owner: filters.owner
      },
      sort: filters.sortFilter,
      sortAscending: filters.sortAscending,
    }

    const responseCustomers = await httpClient.post(process.env.REACT_APP_API_URL + "/api/users/get?page=" + filters.page, requestData)

    dispatch(updateCustomerList(responseCustomers.data[0]))
    dispatch(updateTotalPagesCustomers(responseCustomers.data[1]))
    dispatch(updateArrayOfCitiesCustomers(responseCustomers.data[2]))
  }

  const fetchOwner = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/sales-pipe/owner/get?customerID=${customerID}&includeHistory=${true}`)

    const owner = response.data.owner
    setOwnerOfClient(owner)

    const history = response.data.history || []
    setOwnerHistory(history)
  }

  const fetchCustomerSuppliers = async () => {
    setLoadingCustomerSupplier(true)
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/customer/get-suppliers?customerID=${customerID}`)
    if (response.data) {
      setCustomerSuppliers(response.data.map(supplier => ({
        supplier: {
          id: supplier.supplier_id.id,
          name: supplier.supplier_id.business_name
        },
        alias: supplier.alias
      })))
    } else {
      setCustomerSuppliers([{
        supplier: null,
        alias: ''
      }])
    }

    setLoadingCustomerSupplier(false)
  }

  const fetchCustomerContent = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/customer/content/get?customerID=${customerID}`)
    setCustomerContentImages(response.data.images)
    setCustomerContentFiles(response.data.files)
  }

  const fetchSupplyingCustomers = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/customer/get-supplying-customers?customerID=${customerID}`)
    setSupplyingCustomers(response.data)
  }

  useEffect(() => {
    setMenuItem("contact-details")
  }, [customer])

  useEffect(() => {
    if (customer.type == "wholesaler") {
      setCustomer(prev => ({ ...prev, is_supplier: true }))
    }
  }, [customer.type])

  useEffect(() => {
    if (customer.is_supplier) {
      fetchSupplyingCustomers()
    }
  }, [customer.is_supplier])

  useEffect(() => {
    if (!isCreateMode && userID) {
      fetchCustomer()
    }
  }, [userID])

  useEffect(() => {
    if (customerID) {
      Promise.all([
        fetchCustomCustomerDetails(),
        ["brandowner"].includes(userInfo.app_version) && fetchMyProductSuppliers(),
        !isCreateMode && fetchActivities(),
        fetchOwner(),
        !["admin"].includes(userInfo.type) && fetchMyBrands(),
        userInfo.app_version === "wholesaler" ? fetchCustomerSuppliers() : fetchVendors(),
        !isCreateMode && fetchCustomerContent()
      ]).catch(err => {
        console.log(err)
        setError(true)
      })
    }
  }, [customerID])

  useEffect(() => {
    setCustomDetails(reportItems)
  }, [reportItems])

  useEffect(() => {
    let dropdownElement = null

    if (typesDropdownIsOpen) {
      dropdownElement = dropdownRefTypes.current
    } else if (subTypeDropdownIsOpen) {
      dropdownElement = dropdownRefSubTypes.current
    }

    const parentDivElement = parentDivRef.current

    if (dropdownElement) {
      const dropdownRect = dropdownElement.getBoundingClientRect()
      const parentDivRect = parentDivElement.getBoundingClientRect()

      const { top: dropdownTop, height: dropdownHeight } = dropdownRect
      const { top: parentDivTop, height: parentDivHeight } = parentDivRect

      const dropdownBottom = dropdownTop + dropdownHeight
      const parentDivBottom = parentDivTop + parentDivHeight

      if (dropdownBottom > parentDivBottom) {
        // Calculate the amount to scroll by
        const scrollAmount = dropdownBottom - parentDivBottom
        // Scroll the parent div with smooth animation
        parentDivElement.scrollBy({
          top: scrollAmount,
          behavior: 'smooth',
        })
      }
    }
  }, [typesDropdownIsOpen, subTypeDropdownIsOpen])

  useEffect(() => {
    // Only run if we're on the availability tab
    if (menuItem !== 'availability') return

    const fetchAvailability = async () => {
      setLoadingProductAvailability(true)
      setLoadingMaterialAvailability(true)
      await fetchProductAvailability()
      setLoadingProductAvailability(false)
      setLoadingMaterialAvailability(false)
    }

    fetchAvailability()

  }, [availabilityFilters])

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedBusinessName(customer.business_name)
    }, 750) // 750ms delay

    if (!customer.business_name) {
      setBusinessNameSelected(false)
      setBusinessNameDropdownIsActive(true)
      setGoogleBusinessSearchResults([])
    }

    return () => {
      clearTimeout(timerId)
    }
  }, [customer.business_name])

  useEffect(() => {
    const fetchBusinessName = async () => {
      if (!debouncedBusinessName || !isCreateMode) {
        return
      }

      if (!businessNameSelected) {
        setLoadingBusinessNameDropdown(true)
        try {
          const response = await httpClient.get(`${process.env.REACT_APP_API_URL}/api/user/text-search?textQuery=${debouncedBusinessName}`)

          if (response.status === 200) {
            setGoogleBusinessSearchResults(response.data.data.places)
          } else {
            setBusinessNameDropdownIsActive(false)
            console.log(response.data)
          }
        } catch (error) {
          console.log(error)
          if (error.response?.status === 423) {
            setSearchRequestLimitReached(true)
          }
        }
        setLoadingBusinessNameDropdown(false)
      }
    }

    fetchBusinessName()
  }, [debouncedBusinessName, isCreateMode, businessNameSelected])

  // Update handleNavigation to use the blocking function
  const handleNavigation = (path) => {
    navigate(path)
  }

  // Update handleGoBack to use the blocking function
  const handleGoBack = () => {
    navigate("/customers")
  }

  const handleOnCLickType = () => {
    setTypesDropdownIsOpen(!typesDropdownIsOpen)
  }

  const handleOnCLickSubType = () => {
    setsubTypeDropdownIsOpen(!subTypeDropdownIsOpen)
  }

  const handleDuplicateButton = (event, type) => {
    const newData = { ...customer }

    switch (type) {
      case "name":
        newData["company_name"] = customer.business_name
        break
      case "email":
        newData["invoice_email"] = customer.email
        break
      case "address":
        newData["delivery_address_street"] = customer.invoice_address_street
        newData["delivery_address_pc"] = customer.invoice_address_pc
        newData["delivery_address_nr"] = customer.invoice_address_nr
        newData["delivery_address_place"] = customer.invoice_address_place
        newData["delivery_address_country"] = customer.invoice_address_country
        break
    }

    setCustomer(newData)
  }

  const handleSave = async () => {
    saveButtonRef.current.disabled = true
    setLoadingSaveButton(true)

    const enableButtonAndLoading = () => {
      saveButtonRef.current.disabled = false
      setLoadingSaveButton(false)
    }

    const emailErrorElement = document.getElementById("input_error_email--detail")
    const invoiceEmailErrorElement = document.getElementById("input_error_invoice_email--detail")
    const salesEmailErrorElement = document.getElementById("input_error_sales_email--detail")
    const VATNumberErrorElement = document.getElementById("input_error_VAT_number--detail")

    const VATValidation = /[A-Z][0-9]{4}\.[0-9]{3}\.[0-9]{3}/
    const emailValidation = /\S+@\S+\.\S+/

    if (customer.type == "B2C") {
      if (!customer.first_name) {
        toast.error("First name is required", { theme: "dark" })
        enableButtonAndLoading()
        return
      }
    } else {
      if (!customer.business_name) {
        toast.error("Business name is required", { theme: "dark" })
        enableButtonAndLoading()
        return
      }
    }

    // Check email structure
    if (customer.email && !emailValidation.test(customer.email)) {
      emailErrorElement.classList.add("active")
      toast.error("Invalid email", { theme: "dark" })
      enableButtonAndLoading()
      return
    } else if (customer.invoice_email && !emailValidation.test(customer.invoice_email)) {
      invoiceEmailErrorElement.classList.add("active")
      toast.error("Invalid invoice email", { theme: "dark" })
      enableButtonAndLoading()
      return
    } else if (customer.sales_email && !emailValidation.test(customer.sales_email)) {
      salesEmailErrorElement.classList.add("active")
      toast.error("Invalid sales email", { theme: "dark" })
      enableButtonAndLoading()
      return
    }

    const data = {
      customerID: customerID,
      isCreateMode: isCreateMode,
      contactDetails: customer,
      customDetails: customDetails,
      contactRhythm: contactRhythm,
      owner: ownerOfClient,
      suppliers: customerSuppliers,
      productSuppliers: myProductSuppliers,
      productAvailability: availabilityChanges
    }

    try {
      const response = await toast.promise(httpClient.post(`${process.env.REACT_APP_API_URL}/api/customer/create-or-update`, data), {
        pending: `Saving customer...`,
        error: `Failed to ${isCreateMode ? "create" : "update"} customer`,
        success: `Customer has been ${isCreateMode ? "created" : "updated"}!`
      })

      if (response.status === 200) {
        if (isCreateMode) {
          // Redirect to the new customer's page
          navigate(`/customer/${response.data.customer_user_id}`)
        } else {
          // Existing update logic, stay on the same page
        }

        if (customer.is_supplier) {
          dispatch(fetchSuppliers())
        }

        enableButtonAndLoading()
        return
      }
    } catch (error) {
      console.error(error)
      toast.error("Failed to save customer")
      enableButtonAndLoading()
    }
  }

  const handleBusinessCentralToggle = () => {
    setCustomer(prev => ({
      ...prev,
      create_in_business_central: !prev.create_in_business_central
    }))
  }

  const onChangeCustomerInfo = (event, elementName = null, elementValue = null) => {
    const element = event?.currentTarget
    let name = elementName || element?.name || ""
    let value = elementValue ?? element?.value ?? ""

    const newData = {
      ...customer,
      [name]: value
    }

    // clean sub_type state
    if (name === "type") {
      newData["sub_type"] = ""
    }

    setCustomer(newData)
    setsubTypeDropdownIsOpen(false)
    setTypesDropdownIsOpen(false)

    // Update changesMade state
    setChangesMade(true)
  }

  const downloadEmailFile = async (url) => {
    try {
      const response = await fetch(url)
      const blob = await response.blob()
      const downloadUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = downloadUrl

      // Extract filename from URL
      const urlParts = url.split('/')
      const encodedFilename = urlParts[urlParts.length - 1]
      const filename = decodeURIComponent(encodedFilename)

      link.download = filename
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(downloadUrl)
    } catch (error) {
      console.error('Error downloading file:', error)
      toast.error("Error downloading file", { theme: "dark" })
    }
  }

  const createOrder = () => {
    const createOrderPopup = document.getElementById("popout-create-order")
    createOrderPopup.classList.toggle("active")
  }

  const showSuppliersPopup = (productName, productVolume, productID) => {
    setPopUpSuppliersProduct(`${productName} ${productVolume}`)
    setSelectedProductID(productID)
    setVendorPopupIsActive(true)
  }

  const handleChangeVendorOfProduct = async (vendor) => {
    if (selectedProductID) {
      const updatedProductSuppliers = myProductSuppliers.map(product => ({ ...product }))

      const index = updatedProductSuppliers.findIndex(product => product.product_id === selectedProductID)
      console.log(updatedProductSuppliers[index])
      if (index !== -1) {
        updatedProductSuppliers[index] = {
          ...updatedProductSuppliers[index],
          supplier_id: vendor.id,
          supplier_name: vendor.company_name
        }
        setMyProductSuppliers(updatedProductSuppliers)
      }
    } else {
      // Set all suppliers case
      const updatedProductSuppliers = myProductSuppliers.map(product => ({
        ...product,
        supplier_id: vendor.id,
        supplier_name: vendor.company_name
      }))
      setMyProductSuppliers(updatedProductSuppliers)
    }

    setChangesMade(true)
    setVendorPopupIsActive(false)
  }

  const handleOnMouseOver = (event) => {
    const headerItem = event.currentTarget
    headerItem.classList.add("hovered")
  }

  const handleOnMouseOut = (event) => {
    const headerItem = event.currentTarget
    headerItem.classList.remove("hovered")
  }

  const onClickCheckAvailability = (event) => {
    const productID = parseInt(event.currentTarget.parentElement.getAttribute("data-product-id"))

    // Find the product and its current state BEFORE any updates
    const product = availabilityState.productsAvailability.find(p => p.id === productID)
      || availabilityState.materialsAvailability.find(p => p.id === productID)
    const newAvailability = !product.availability

    // Update the UI state
    setAvailabilityState(prevState => {
      const newState = { ...prevState }

      // Update products or materials based on which list contains the product
      const lists = ['productsAvailability', 'materialsAvailability']
      lists.forEach(list => {
        const index = newState[list].findIndex(item => item.id === productID)
        if (index !== -1) {
          newState[list][index] = {
            ...newState[list][index],
            availability: newAvailability
          }
        }
      })

      return newState
    })

    // Track the change
    setAvailabilityChanges(prevChanges => {
      const existingChangeIndex = prevChanges.findIndex(change => change.id === productID)

      if (existingChangeIndex !== -1) {
        // If this change would revert to original state, remove it from changes
        const originalAvailability = product.availability
        if (newAvailability === originalAvailability) {
          return prevChanges.filter(change => change.id !== productID)
        }

        // Otherwise update the existing change
        const newChanges = [...prevChanges]
        newChanges[existingChangeIndex] = {
          ...newChanges[existingChangeIndex],
          availability: newAvailability
        }
        return newChanges
      }

      // Add new change
      return [...prevChanges, {
        id: productID,
        availability: newAvailability,
        is_POSM: product.is_POSM
      }]
    })

    setChangesMade(true)
  }

  const handleClickRow = (event) => {
    // Find the closest parent row to the clicked element
    const parentRow = event.target.closest('.table__row-9')

    // If a parent row is found
    if (parentRow) {
      // Find the next sibling element which should be the .activity__detail div
      const detailDiv = parentRow.nextElementSibling

      // Check if the next sibling is actually the .activity__detail div
      if (detailDiv && detailDiv.classList.contains('activity__detail')) {
        detailDiv.classList.toggle('hide')
      }
    }
  }

  const handlePreCheckDone = async (id) => {
    const activity = activities.find(obj => obj.id == id)
    setSelectedActivity(activity)

    if (userInfo.type != "manager" & userInfo.id != activity.sales_rep_id.id) {
      dispatch(changeNotificationMessage("You can look but you can't touch 🤖. You are not the owner of this activity."))
      showNotificationPopupMessage()
      return
    }

    if (activity.done) {
      const data = {
        "ids": [id]
      }

      const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/activities/change-done-status?id=${id}`, data)
      console.log(response.data)

      fetchActivities()
    } else {
      setCreateActivityReport(true)
    }

  }

  const setChangesMadeCustomDetails = (value) => {
    setChangesMade(true)
  }

  function renderCustomerDetail(details) {
    return details.map((detail, index) => {
      switch (detail.type) {
        case 'checkmark':
          return <CheckBoxCustomDetails
            key={index}
            setChangesMade={setChangesMadeCustomDetails}
            index={index}
            text={detail.title}
            defaultState={detail.value}
            state={customDetails}
            setState={setCustomDetails}
            textPosition="vert"
            margin="0"
          />
        case 'input':
          return <InputCustomDetails
            key={index}
            setChangesMade={setChangesMadeCustomDetails}
            index={index}
            text={detail.title}
            defaultState={detail.value}
            state={customDetails}
            setState={setCustomDetails}
            inputBoxSize={100}
            fontSize="0.75rem"
            textPosition="vert"
          />
        case 'dropdown':
          return <DropDownCustomDetails
            key={index}
            setChangesMade={setChangesMadeCustomDetails}
            index={index}
            title={detail.title}
            defaultState={detail.value}
            state={customDetails}
            setState={setCustomDetails}
            listArray={detail.dropdown_values}
            inherit={true}
            marginTop="0"
          />
        default:
          return null
      }
    })
  }

  const handleEditActivity = (event, id) => {
    event.stopPropagation()

    const activity = activities.find(obj => obj.id == id)
    setSelectedActivity(activity)

    if (userInfo.type != "manager" & userInfo.id != activity.sales_rep_id.id) {
      dispatch(changeNotificationMessage("You can look but you can't touch 🤖. You are not the owner of this activity."))
      showNotificationPopupMessage()
      return
    }

    setEditActivity(true)
    setCreateActivity(true)
  }

  const handleChangeOwner = async (owner) => {
    setOwnerOfClient(owner)
    setChangesMade(true)
  }

  const handleOnClickLoadMore = (type) => {

    const updatedState = { ...availabilityState }

    if (type == 'products') {
      updatedState['loadingMoreProducts'] = true
      updatedState['productsPage'] = updatedState['productsPage'] + 1
    } else if (type == 'materials') {
      updatedState['loadingMoreMaterials'] = true
      updatedState['materialsPage'] = updatedState['materialsPage'] + 1
    }

    setAvailabilityState(updatedState)
  }

  const handleHideUnavailable = () => {
    const updatedState = { ...availabilityFilters }
    updatedState['hideUnavailable'] = !updatedState['hideUnavailable']
    setAvailabilityFilters(updatedState)
  }

  const handleIsSupplier = (value) => {
    setCustomer(prev => ({ ...prev, is_supplier: !prev.is_supplier }))
    setChangesMade(true)
  }

  const handleChangeContactRhythm = async (value) => {
    setContactRhythm(value)
    setChangesMade(true)
  }

  const [newSupplier, setNewSupplier] = useState({
    supplier: null,
    alias: ''
  })

  const handleSupplierChange = (supplier) => {
    setNewSupplier(prev => ({
      ...prev,
      supplier: supplier
    }))
    setChangesMade(true)
  }

  const handleAliasChange = (e) => {
    setNewSupplier(prev => ({
      ...prev,
      alias: e.target.value
    }))
    setChangesMade(true)
  }

  const addNewSupplierRow = () => {
    if (!newSupplier.supplier) {
      toast.error("Please select a supplier first")
      return
    }

    setCustomerSuppliers(prev => [...prev, newSupplier])
    setNewSupplier({ supplier: null, alias: '' }) // Reset for next entry
    setChangesMade(true)
  }

  // Function to handle deleting a supplier
  const handleDeleteSupplier = async (event, supplierID) => {
    event.stopPropagation()
    const button = event.currentTarget
    button.disabled = true

    setCustomerSuppliers(prev => prev.filter(supplier => supplier.supplier.id !== supplierID))
    button.disabled = false
    setChangesMade(true)
  }

  const handleOnClickSearchResult = async (searchResult) => {
    setLoadingInputBoxes(true)
    setBusinessNameDropdownIsActive(false)
    setBusinessNameSelected(true)
    setGoogleBusinessSearchResults([])

    const customerState = { ...customer }
    customerState.business_name = searchResult.displayName.text
    customerState.tel_number = searchResult.nationalPhoneNumber

    // try to find the email address, type & subtype and short description
    try {
      const response = await httpClient.post(
        `${process.env.REACT_APP_API_URL}/api/ai/find-customer-info`,
        searchResult
      )

      const emailAddresses = response.data.email_addresses
      const customerAddress = response.data.customer_address

      if (customerAddress) {
        customerState.delivery_address_street = customerAddress.street_name
        customerState.delivery_address_nr = customerAddress.street_nr
        customerState.delivery_address_pc = customerAddress.postal_code
        customerState.delivery_address_place = customerAddress.city
        customerState.delivery_address_country = customerAddress.country
        customerState.invoice_address_country = customerAddress.country
      }

      if (emailAddresses?.length > 0) {
        customerState.email = emailAddresses[0]
      }

      customerState.bio = response.data.short_description
      customerState.type = response.data.type
      customerState.sub_type = response.data.sub_type

      setCustomer(customerState)
    } catch (error) {
      console.error('Error fetching additional customer info:', error)
    }

    setLoadingInputBoxes(false)
  }

  // Helper function to get the most up-to-date availability state
  const getProductAvailability = (product) => {
    const change = availabilityChanges.find(change => change.id === product.id)
    return change !== undefined ? change.availability : product.availability
  }

  return (
    <div className="template">
      {/* {error ? window.location.href = "/" : ""} */}

      <NotificationPopupMessage />

      <PopOutWindowCreateOrder
        popupID={"popout-create-order"}
        customer={customer}
        selectedCustomerID={selectedCustomerID}
        selectedCustomer={customer.business_name}
        setSelectedCustomerID={setSelectedCustomerID}
        setNewCustomerPopupIsActive={setNewCustomerPopupIsActive}
      />

      <PopOutWindowCreateActivity
        isActive={createActivity}
        setIsActive={setCreateActivity}
        popupID={"popout-create-activity"}
        customers={[]}
        selectedCustomer={customer.business_name}
        selectedCustomerID={selectedCustomerID}
        setSelectedCustomerID={setSelectedCustomerID}
        setNewCustomerPopupIsActive={setNewCustomerPopupIsActive}
        fetchActivities={fetchActivities}
        myBrands={myBrands}
        type="customer-info"
        editActivity={editActivity}
        setEditActivity={setEditActivity}
        selectedActivity={selectedActivity}
        myTeam={myTeam}
        keepCustomerState={true}
      />

      <PopOutWindowCreateActivityReport
        isActive={createActivityReport}
        setIsActive={setCreateActivityReport}
        selectedActivity={selectedActivity}
        setSelectedActivity={setSelectedActivity}
        customers={[]}
        popupID={"popout-create-activity-report"}
        selectedCustomerID={customer.id}
        setSelectedCustomerID={setSelectedCustomerID}
        selectedCustomer={customer.business_name}
        setSelectedCustomer={setSelectedCustomer}
        setNewCustomerPopupIsActive={setNewCustomerPopupIsActive}
        fetchActivities={fetchActivities}
        myBrands={myBrands}
        type="customer-info"
        keepCustomerState={true}
      />

      <Header>
        <div className="back_button" onClick={handleGoBack}><KeyboardBackspaceIcon /> Go Back</div>
        {
          !isCreateMode &&
          <div className="header__options" id="header__options">
            <button className="options-button-header" onClick={() => setCreateActivity(true)}>Create Activity</button>
            <button className="options-button-header" onClick={createOrder}>Create Order</button>
          </div>
        }

        <div className="header__options--right">
          {
            menuItem == "availability" ?
              <div className="header__option--slider"><Slider size={1.2} text="Hide unavailable" onClickFunction={handleHideUnavailable} state={availabilityFilters.hideUnavailable} /></div>
              : null
          }
          <button
            className="green-button-header"
            id="customers__btn-edit"
            onClick={handleSave}
            ref={saveButtonRef}
          >
            {loadingSaveButton ?
              <CircularProgress color='inherit' size="1rem" /> :
              isCreateMode ? "CREATE" : "SAVE"
            }
          </button>
        </div>
      </Header>
      <MenuBar />

      <NotificationTop />

      <div className="template__body">
        <div className="body__customer-info" id="body-customer-info">

          <PopupWindowCreateNewCustomer
            isActive={newCustomerPopupIsActive}
            setIsActive={setNewCustomerPopupIsActive}
            fetchCustomers={fetchCustomers}
            setSelectedCustomer={setSelectedCustomer}
            setSelectedCustomerID={setSelectedCustomerID}
          />

          <PopOutWindow popupID="popup-window--customerInfoPage--suppliers" isActive={vendorPopupIsActive} setIsActive={setVendorPopupIsActive} overflow="hidden">
            <h4 className="popup-window__details__item--title">{popUpSuppliersProduct}</h4>
            <input className="search-bar dropdown" placeholder="Search..." value={searchValueSupplier} onChange={event => setSearchValueSupplier(event.currentTarget.value)} autoComplete="off" />
            <ul className="popup_window__suppliers_list overflow-scroll">
              {
                vendors
                  .filter(supplier => supplier.company_name.toLowerCase().includes(searchValueSupplier.toLowerCase()))
                  .map((item, index) => {
                    return (
                      <li className='supplier__item' key={index} onClick={() => handleChangeVendorOfProduct(item)}>
                        <div className='supplier__item__company_name'>{item.company_name}</div>
                        <div className='supplier__item__contact_email'>{item.contact_email}</div>
                        <div>
                          {
                            ['BRANDOWNER'].includes(item.type) ?
                              <div className='label__vendors'><Label title="Brandowner" labelColor="blue" /></div> :
                              item.is_pseudo ?
                                <div className='label__vendors'><Label title="Added by customer" labelColor="green" /></div> :
                                <div className='label__vendors'></div>
                          }
                        </div>
                      </li>
                    )
                  })
              }
            </ul>
          </PopOutWindow>

          <div className="body__section__header">
            {
              userInfo.type != "admin" &&
              <>
                <div className={`body__section__header_item ${menuItem == "contact-details" ? "selected" : ""}`} data-id="header-contact-details" onClick={() => setMenuItem("contact-details")} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Contact details</div>
                <div className={`body__section__header_item ${menuItem == "details" ? "selected" : ""}`} data-id="header-details" onClick={() => setMenuItem("details")} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Details</div>
                <div className={`body__section__header_item ${menuItem == "availability" ? "selected" : ""}`} data-id="header-availability" onClick={() => setMenuItem("availability")} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Availability</div>
                {
                  ["manager", "salesforce"].includes(userInfo.type) ?
                    <div className={`body__section__header_item ${menuItem == "suppliers" ? "selected" : ""}`} data-id="header-suppliers" onClick={() => setMenuItem("suppliers")} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Suppliers</div> : null
                }

                {
                  !isCreateMode &&
                  <>
                    <div className={`body__section__header_item ${menuItem == "content" ? "selected" : ""}`} data-id="header-content" onClick={() => setMenuItem("content")} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Content</div>
                    <div className={`body__section__header_item ${menuItem == "activities" ? "selected" : ""}`} data-id="header-activities" onClick={() => setMenuItem("activities")} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Activities</div>
                  </>
                }
              </>
            }
          </div>

          {
            menuItem == "contact-details" &&

            <div className="body__section" id="customer-info__section">
              <div className="form__section no-scrollbar" ref={parentDivRef}><h1>Details</h1>
                <div className="input_field">
                  <label className="input_field__label" htmlFor="business_name">Business name</label>
                  <input
                    className={`input_field__input_box--text ${loading || loadingInputBoxes ? "loading" : ""}`}
                    id="customers__business_name"
                    type="text"
                    name="business_name"
                    value={customer.business_name}
                    onChange={onChangeCustomerInfo}
                    disabled={loadingInputBoxes}
                  />
                  {isCreateMode && (
                    <div className={`dropdown_field ${(businessNameDropdownIsActive && customer.business_name !== '') ? "" : "hide"}`}>
                      {searchRequestLimitReached ? (
                        <div className="limit" onClick={() => setBusinessNameDropdownIsActive(false)}>
                          <p>🤖 You've reached your monthly search limit.</p>
                          <IoClose />
                        </div>
                      ) : loadingBusinessNameDropdown ? (
                        <div className='circular_loading_spinner__wrapper'>
                          <CircularProgress color='inherit' size="1rem" />
                        </div>
                      ) : (
                        <>
                          {googleBusinessSearchResults.map((result, index) => {
                            const addressParts = result.formattedAddress.split(', ')
                            const place = addressParts.length >= 3 ? addressParts[addressParts.length - 2].split(' ')[1] : ''

                            return (
                              <div
                                className="dropdown_field__item"
                                key={index}
                                onClick={() => handleOnClickSearchResult(result)}
                              >
                                <div>{result.displayName.text}</div>
                                <div>{result.primaryType}</div>
                                <div>{place}</div>
                              </div>
                            )
                          })}
                        </>
                      )}
                    </div>
                  )}
                </div>

                <div className="input_field">
                  <label className="input_field__label" htmlFor="email">Email</label>
                  <input
                    className={`input_field__input_box--text ${loading || loadingInputBoxes ? "loading" : ""}`}
                    id="customers__email--detail"
                    type="text"
                    name="email"
                    value={customer.email}
                    onChange={onChangeCustomerInfo}
                    disabled={loadingInputBoxes}
                  />
                </div>
                <div className="input_error" id="input_error_email--detail">{emailError}</div>

                <div className="input_field">
                  <label className="input_field__label" htmlFor="tel">Telephone number</label>
                  <input
                    className={`input_field__input_box--text ${loading || loadingInputBoxes ? "loading" : ""}`}
                    id="customers__tel_number--detail"
                    type="text"
                    name="tel_number"
                    value={customer.tel_number}
                    onChange={onChangeCustomerInfo}
                    disabled={loadingInputBoxes}
                  />
                </div>

                <div className="input_field--combo-5050">
                  <div className="input_field">
                    <label className="input_field__label" htmlFor="first_name">First name</label>
                    <input
                      className={`input_field__input_box--text ${loading || loadingInputBoxes ? "loading" : ""}`}
                      id="customers__first_name--detail"
                      type="text"
                      name="first_name"
                      value={customer.first_name}
                      onChange={onChangeCustomerInfo}
                      disabled={loadingInputBoxes}
                    />
                  </div>
                  <div className="input_field">
                    <label className="input_field__label" htmlFor="last_name">Last name</label>
                    <input
                      className={`input_field__input_box--text ${loading || loadingInputBoxes ? "loading" : ""}`}
                      id="customers__last_name--detail"
                      type="text"
                      name="last_name"
                      value={customer.last_name}
                      onChange={onChangeCustomerInfo}
                      disabled={loadingInputBoxes}
                    />
                  </div>
                </div>

                <div className="input_field--combo-5050">
                  <div className="input_field">
                    <div className="dropdown">
                      <p className="input_field__label">Type</p>
                      <div
                        className={`input_field__input_box--text dropdown ${loading || loadingInputBoxes ? "loading" : ""}`}
                        id="customers__type--detail"
                        onClick={handleOnCLickType}
                        disabled={loadingInputBoxes}
                      >
                        {customer.type}
                      </div>
                    </div>
                    <ul className={`input_field__dropdown ${typesDropdownIsOpen ? "active" : ""}`} id="dropdown__customers_type" ref={dropdownRefTypes}>
                      {
                        arrayOfTypes.map((type, index) => {
                          return <li key={index} name="type" onClick={(e) => onChangeCustomerInfo(e, "type", type)}>{type}</li>
                        })
                      }
                    </ul>
                  </div>
                  <div className="input_field">
                    <div className="dropdown">
                      <p className="input_field__label">Sub type</p>
                      <div
                        className={`input_field__input_box--text dropdown ${loading || loadingInputBoxes ? "loading" : ""}`}
                        id="customers__sub_type--detail"
                        onClick={handleOnCLickSubType}
                        disabled={loadingInputBoxes}
                      >
                        {customer.sub_type}
                      </div>
                    </div>
                    <ul className={`input_field__dropdown ${subTypeDropdownIsOpen ? "active" : ""}`} id="dropdown__customers_sub_type" ref={dropdownRefSubTypes}>
                      {
                        customerTypesArray[customer ? customer.type : ""]?.map((subtype, index) => {
                          return <li key={index} name="sub_type" onClick={(e) => onChangeCustomerInfo(e, "sub_type", subtype)}>{subtype}</li>
                        })
                      }
                    </ul>
                  </div>

                </div>
                {
                  customer.is_supplier &&
                  <>
                    <div className="input_field">
                      <label className="input_field__label" htmlFor="sales_email">Order email</label>
                      <div className="flex">
                        <input
                          className={`input_field__input_box--text ${loading || loadingInputBoxes ? "loading" : ""}`}
                          id="customers__sales_email--detail"
                          type="text"
                          name="sales_email"
                          value={customer.sales_email}
                          onChange={onChangeCustomerInfo}
                          disabled={loadingInputBoxes}
                        />
                      </div>
                    </div>
                    <div className="input_error" id="input_error_sales_email--detail">{emailError}</div>
                  </>
                }
                <div className="input_field">
                  <label className="input_field__label" htmlFor="bio">Bio</label>
                  <textarea
                    className={`input_field__input_box--text ${loading || loadingInputBoxes ? "loading" : ""}`}
                    style={{ "minHeight": "90px" }}
                    id="customers__bio--detail"
                    name="bio"
                    value={customer.bio}
                    onChange={onChangeCustomerInfo}
                    disabled={loadingInputBoxes}
                  />
                </div>
              </div>

              <div className="form__section no-scrollbar"><h1>Invoicing</h1>
                <div className="input_field">
                  <label className="input_field__label" htmlFor="company_name">Company name</label>
                  <div className="flex">
                    <input
                      className={`input_field__input_box--text ${loading || loadingInputBoxes ? "loading" : ""}`}
                      id="customers__company_name--detail"
                      type="text"
                      name="company_name"
                      value={customer.company_name}
                      onChange={onChangeCustomerInfo}
                      disabled={loadingInputBoxes}
                    />
                    <button className="secondairy-button" onClick={e => handleDuplicateButton(e, "name")}>Duplicate</button>
                  </div>
                </div>
                <div className="input_field">
                  <label className="input_field__label" htmlFor="invoice_email">Invoice email</label>
                  <div className="flex">
                    <input
                      className={`input_field__input_box--text ${loading || loadingInputBoxes ? "loading" : ""}`}
                      id="customers__invoice_email--detail"
                      type="text"
                      name="invoice_email"
                      value={customer.invoice_email}
                      onChange={onChangeCustomerInfo}
                      disabled={loadingInputBoxes}
                    />
                    <button className="secondairy-button" onClick={e => handleDuplicateButton(e, "email")}>Duplicate</button>
                  </div>
                </div>
                <div className="input_error" id="input_error_invoice_email">{invoiceEmailError}</div>
                <div className="input_field">
                  <label className="input_field__label" htmlFor="VAT_number">VAT number</label>
                  <input
                    className={`input_field__input_box--text ${loading || loadingInputBoxes ? "loading" : ""}`}
                    id="customers__VAT_number--detail"
                    type="text"
                    name="VAT_number"
                    value={customer.VAT_number}
                    onChange={onChangeCustomerInfo}
                    disabled={loadingInputBoxes}
                  />
                </div>
                <div className="input_error" id="input_error_VAT_number--detail">{VATNumberError}</div>
                <div className="input_field--combo-7030">
                  <div className="input_field">
                    <label className="input_field__label" htmlFor="invoice_address_street">Street</label>
                    <input
                      className={`input_field__input_box--text ${loading || loadingInputBoxes ? "loading" : ""}`}
                      id="customers__invoice_address_street--detail"
                      type="text"
                      name="invoice_address_street"
                      value={customer.invoice_address_street}
                      onChange={onChangeCustomerInfo}
                      disabled={loadingInputBoxes}
                    />
                  </div>
                  <div className="input_field">
                    <label className="input_field__label" htmlFor="invoice_address_nr">Number</label>
                    <input
                      className={`input_field__input_box--text ${loading || loadingInputBoxes ? "loading" : ""}`}
                      id="customers__invoice_address_nr--detail"
                      type="text"
                      name="invoice_address_nr"
                      value={customer.invoice_address_nr}
                      onChange={onChangeCustomerInfo}
                      disabled={loadingInputBoxes}
                    />
                  </div>
                </div>
                <div className="input_field--combo-3070">
                  <div className="input_field">
                    <label className="input_field__label" htmlFor="invoice_address_pc">Postal code</label>
                    <input
                      className={`input_field__input_box--text ${loading || loadingInputBoxes ? "loading" : ""}`}
                      id="customers__invoice_address_pc--detail"
                      type="text"
                      name="invoice_address_pc"
                      value={customer.invoice_address_pc}
                      onChange={onChangeCustomerInfo}
                      disabled={loadingInputBoxes}
                    />
                  </div>
                  <div className="input_field">
                    <label className="input_field__label" htmlFor="invoice_address_place">City</label>
                    <input
                      className={`input_field__input_box--text ${loading || loadingInputBoxes ? "loading" : ""}`}
                      id="customers__invoice_address_place--detail"
                      type="text"
                      name="invoice_address_place"
                      value={customer.invoice_address_place}
                      onChange={onChangeCustomerInfo}
                      disabled={loadingInputBoxes}
                    />
                  </div>
                </div>
                <div className="input_field">
                  <label className="input_field__label" htmlFor="invoice_address_country">Country</label>
                  <input
                    className={`input_field__input_box--text ${loading || loadingInputBoxes ? "loading" : ""}`}
                    id="customers__invoice_address_country--detail"
                    type="text"
                    name="invoice_address_country"
                    value={customer.invoice_address_country}
                    onChange={onChangeCustomerInfo}
                    disabled={loadingInputBoxes}
                  />
                </div>

              </div>

              <div className="form__section no-scrollbar" id="delivery_address_info"><h1>Delivery</h1>
                <div className="input_field--combo-7030">
                  <div className="input_field">
                    <label className="input_field__label" htmlFor="delivery_address_street">Street</label>
                    <input
                      className={`input_field__input_box--text ${loading || loadingInputBoxes ? "loading" : ""}`}
                      id="customers__delivery_address_street--detail"
                      type="text"
                      name="delivery_address_street"
                      value={customer.delivery_address_street}
                      onChange={onChangeCustomerInfo}
                      disabled={loadingInputBoxes}
                    />
                  </div>
                  <div className="input_field">
                    <label className="input_field__label" htmlFor="delivery_address_nr">Number</label>
                    <input
                      className={`input_field__input_box--text ${loading || loadingInputBoxes ? "loading" : ""}`}
                      id="customers__delivery_address_nr--detail"
                      type="text"
                      name="delivery_address_nr"
                      value={customer.delivery_address_nr}
                      onChange={onChangeCustomerInfo}
                      disabled={loadingInputBoxes}
                    />
                  </div>
                </div>
                <div className="input_field--combo-3070">
                  <div className="input_field">
                    <label className="input_field__label" htmlFor="delivery_address_pc">Postal code</label>
                    <input
                      className={`input_field__input_box--text ${loading || loadingInputBoxes ? "loading" : ""}`}
                      id="customers__delivery_address_pc--detail"
                      type="text"
                      name="delivery_address_pc"
                      value={customer.delivery_address_pc}
                      onChange={onChangeCustomerInfo}
                      disabled={loadingInputBoxes}
                    />
                  </div>
                  <div className="input_field">
                    <label className="input_field__label" htmlFor="delivery_address_place">City</label>
                    <input
                      className={`input_field__input_box--text ${loading || loadingInputBoxes ? "loading" : ""}`}
                      id="customers__delivery_address_place--detail"
                      type="text"
                      name="delivery_address_place"
                      value={customer.delivery_address_place}
                      onChange={onChangeCustomerInfo}
                      disabled={loadingInputBoxes}
                    />
                  </div>
                </div>
                <div className="input_field">
                  <label className="input_field__label" htmlFor="delivery_address_couintry">Country</label>
                  <input
                    className={`input_field__input_box--text ${loading || loadingInputBoxes ? "loading" : ""}`}
                    id="customers__delivery_address_country--detail"
                    type="text"
                    name="delivery_address_country"
                    value={customer.delivery_address_country}
                    onChange={onChangeCustomerInfo}
                    disabled={loadingInputBoxes}
                  />
                </div>
                <button className="secondairy-button" id="duplicate-btn-delivery" onClick={e => handleDuplicateButton(e, "address")}>Duplicate</button>

                <div className="other_options">
                  <CheckBox
                    id="customers__is_supplier"
                    text="Is supplier"
                    state={customer.is_supplier}
                    setData={handleIsSupplier}
                    disabled={loadingInputBoxes}
                  />
                </div>
                <div className={`api_options ${isCreateMode ? "" : "hide"}`}>
                  <div
                    className={`business-central-icon ${customer.create_in_business_central ? 'clicked' : ''} ${!userInfo.api_partners.includes('BusinessCentral') ? "hide" : ""}`}
                    onClick={handleBusinessCentralToggle}
                  >
                    <TfiMicrosoftAlt />
                  </div>
                </div>
              </div>

            </div>

          }

          {
            menuItem == "details" &&

            <div className="body__section--board" id="customer-details__section">

              <div className="classification_section" id="custom-details">
                <h1>Custom details</h1>
                <div className="classification_section__content_container">
                  {
                    loadingCustomDetails ?
                      <LoadingSpinner /> :

                      <>
                        {

                          customDetails.length != 0 ?
                            <div className="custom_details__wrapper">
                              {
                                renderCustomerDetail(customDetails)
                              }
                            </div> : null
                        }
                      </>
                  }
                </div>
              </div>

              <div className="classification_section overflow-hidden" id="ownership">
                <h1>Client owned by</h1>
                <div className="overflow-scroll">
                  <PopupDropdown
                    options={myTeam}
                    value={ownerOfClient}
                    onChange={handleChangeOwner}
                    title="Select Owner"
                  />

                  <h2>History</h2>
                  {
                    ownerHistory.map((historyLine, index) => (
                      <div className="history_line">
                        <div>{formatDate(historyLine.time_stamp)}</div>
                        <div>{historyLine.owner_name}</div>
                      </div>
                    ))
                  }
                </div>
              </div>

              <div className="classification_section overflow-hidden" id="contact-rhythm">
                <h1>Contact rhythm</h1>
                <div className="overflow-scroll">
                  <PopupDropdown
                    options={customerContactRhythmsArray}
                    value={contactRhythm}
                    onChange={handleChangeContactRhythm}
                    title="Select Contact Rhythm"
                  />

                  <h2>Days to next contact</h2>
                  <div id="days-to-next-contact">
                    {customer?.contact_rhythm?.days_to_next_contact}
                  </div>
                </div>
              </div>

              <div className="classification_section overflow-hidden" id="estimates">
                <h1>Estimates</h1>
                <ComingSoonOverlay />
              </div>

              <div className="classification_section overflow-hidden" id="segmentation">
                <h1>Segmentation</h1>
                <ComingSoonOverlay />
              </div>

              <div className="classification_section overflow-hidden" id="business-details">
                <h1>Business details</h1>
                <ComingSoonOverlay />
              </div>

            </div>

          }

          {
            menuItem == "availability" &&

            <div className="body__section--board" id="customer-availability__section">

              <div className="availability_section" id="available-products">
                <h1>Available products</h1>
                <SearchBarList
                  filters={availabilityFilters}
                  updateFilters={setAvailabilityFilters}
                  stateKey={"searchWord"}
                />
                <div className="availability_section__content_container">
                  {loadingProductAvailability ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      {availabilityState.productsAvailability
                        .filter(product => !availabilityFilters.hideUnavailable || getProductAvailability(product))
                        .map((product, index) => (
                          <div className="product" key={index} data-product-id={product.id}>
                            <p>{product.name} {product.volume}</p>
                            <CheckBoxStatic
                              labelDisabled={true}
                              state={getProductAvailability(product)}
                              onClick={onClickCheckAvailability}
                            />
                          </div>
                        ))}
                      {
                        availabilityState.productsHasMore ?
                          <button className="load_more_button" onClick={() => handleOnClickLoadMore("products")}>{availabilityState.loadingMoreProducts ? <CircularProgress color="inherit" size="1rem" /> : "Load more..."}</button>
                          : null
                      }
                    </>
                  )}
                </div>
              </div>

              <div className="availability_section" id="available-POSM">
                <h1>Available POSM</h1>
                <SearchBarList
                  filters={availabilityFilters}
                  updateFilters={setAvailabilityFilters}
                  stateKey={"searchWord"}
                />
                <div className="availability_section__content_container">
                  {loadingProductAvailability ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      {availabilityState.materialsAvailability &&
                        availabilityState.materialsAvailability
                          .map((product, index) => (
                            <div className="product" key={index} data-product-id={product.id}>
                              <p>{product.name} {product.volume}</p>
                              <CheckBoxStatic
                                labelDisabled={true}
                                state={getProductAvailability(product)}
                                onClick={onClickCheckAvailability}
                              />
                            </div>
                          ))}
                      {
                        availabilityState.materialsHasMore ?
                          <button className="load_more_button" onClick={() => handleOnClickLoadMore("materials")}>{availabilityState.loadingMoreMaterials ? <CircularProgress color="inherit" size="1rem" /> : "Load more..."}</button>
                          : null
                      }
                    </>
                  )}
                </div>
              </div>

              <div className="availability_section overflow-hidden" id="latest-report">
                <h1>Estimates</h1>
                <ComingSoonOverlay />
              </div>

            </div>

          }


          {
            menuItem == "suppliers" &&

            <div className="body__section" id="customer-suppliers__section">

              {
                ["wholesaler"].includes(userInfo.app_version) && (
                  <div className="suppliers_container">
                    <div className="customer_suppliers">
                      <h1 className="body__section_title">Suppliers</h1>
                      {loadingCustomerSupplier ? (
                        <div className="supplier_item">
                          <div className={`supplier_item__name skeleton`}></div>
                          <div className={`supplier_item__alias skeleton`}></div>
                          <button className={`delete-btn hide`} onClick={() => null}>
                            <DeleteIcon />
                          </button>
                        </div>
                      ) : (
                        <div className="suppliers_list">
                          <div className="add-supplier">
                            <PopupDropdown
                              searchBar={true}
                              options={suppliers}
                              value={newSupplier.supplier}
                              onChange={handleSupplierChange}
                              title="Select Supplier"
                              inputBoxHeight={"100%"}
                            />
                            <input
                              className="input_field__input_box--text"
                              placeholder="Alias (optional)"
                              value={newSupplier.alias}
                              onChange={handleAliasChange}
                            />
                            <button
                              className="add--btn"
                              onClick={addNewSupplierRow}
                              id="add-supplier-btn"
                            >
                              <AddIcon />
                            </button>
                          </div>

                          {customerSuppliers.map(supplier => (
                            supplier.supplier && (
                              <div className="supplier_item" key={supplier.id}>
                                <div className="supplier_item__name">
                                  {supplier.supplier.name}
                                </div>
                                <div className="supplier_item__alias">{supplier.alias}</div>
                                <button
                                  className="delete-btn"
                                  onClick={(e) => handleDeleteSupplier(e, supplier.supplier.id)}
                                >
                                  <DeleteIcon />
                                </button>
                              </div>
                            )
                          ))}
                        </div>
                      )}
                    </div>

                    {
                      customer.is_supplier && (
                        <div className="supplying_customers">
                          <h1 className="body__section_title">Supplying customers</h1>
                          <div className="supplying_customers_list">
                            {
                              supplyingCustomers.map(supplyingCustomer => (
                                <div className="supplying_customer" onClick={() => handleNavigation(`/customer/${supplyingCustomer.user_id.id}`)} key={supplyingCustomer.id}>
                                  <div className="supplying_customer__name">{supplyingCustomer.business_name}</div>
                                  <div className="supplying_customer__alias">{supplyingCustomer.delivery_address_place}</div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      )
                    }
                  </div>
                )
              }

              {
                ["brandowner"].includes(userInfo.app_version) &&
                <div className="suppliers_my_products_container">
                  <h1 className="body__section_title">Suppliers of my products</h1>
                  <div className="set_all_suppliers">
                    <button className="input_field__input_box--text static" onClick={() => showSuppliersPopup("All products", "", "")}>Set for all products</button>
                  </div>
                  <div className="suppliers_my_products_list">
                    <div className="products_list">
                      <h1>Products</h1>
                      <div className="products_list_container">
                        {
                          myProductSuppliers
                            .filter(product => !product.is_POSM)
                            .map((productSupplier) => {
                              return (
                                <div className="grid--2-colums-50-50" style={{ marginBottom: "5px" }} key={productSupplier.product_id}>
                                  <div className="input_field__label">{productSupplier.product_name} {productSupplier.product_volume}</div>
                                  <div
                                    className="input_field__input_box--text static"
                                    onClick={() => showSuppliersPopup(productSupplier.product_name, productSupplier.product_volume, productSupplier.product_id)}
                                  >
                                    {productSupplier.supplier_name || `Select a supplier for ${productSupplier.product_name} ${productSupplier.product_volume}`}
                                  </div>
                                </div>
                              )
                            })
                        }
                      </div>
                    </div>
                    <div className="products_list">
                      <h1>POSM</h1>
                      <div className="products_list_container">
                        {
                          myProductSuppliers
                            .filter(product => product.is_POSM)
                            .map((productSupplier) => {
                              return (
                                <div className="grid--2-colums-50-50" style={{ marginBottom: "5px" }} key={productSupplier.product_id}>
                                  <div className="input_field__label">{productSupplier.product_name} {productSupplier.product_volume}</div>
                                  <div
                                    className="input_field__input_box--text static"
                                    onClick={() => showSuppliersPopup(productSupplier.product_name, productSupplier.product_volume, productSupplier.product_id)}
                                  >
                                    {productSupplier.supplier_name || `Select a supplier for ${productSupplier.product_name} ${productSupplier.product_volume}`}
                                  </div>
                                </div>
                              )
                            })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              }

            </div>

          }

          {
            menuItem == "content" &&
            <div className="body__section" id="customer-content__section">
              <h2>Images</h2>
              <CustomerContentGrid
                customerID={customer.id}
                content={customerContentImages}
                fetchCustomerContent={fetchCustomerContent}
                isMobile={false}
                imageOnly={true}
              />
              <h2>Files</h2>
              <CustomerContentGrid
                customerID={customer.id}
                content={customerContentFiles}
                fetchCustomerContent={fetchCustomerContent}
                isMobile={false}
                imageOnly={false}
                width={"50%"}
              />
            </div>
          }

          {
            menuItem == "activities" &&

            <div className="body__section" id="customer-visits__section">

              {
                loadingActivities ?
                  <LoadingSpinner /> :
                  <div className="overflow-scroll no-scrollbar">
                    <div className="table">
                      <div className="table__header-9">
                        <TableCheckmarkHeader
                          id={`table-checkmark-header-activities`}
                          selectedRows={selectedRows}
                          setSelectedRows={setSelectedRows}
                        />
                        <div className="table__header--field flex--center">Type</div>
                        <div className="table__header--field">Customer</div>
                        <div className="table__header--field">Title</div>
                        <div className="table__header--field">Owner</div>
                        <div className="table__header--field">Due date</div>
                        <div className="table__header--field flex--center">Priority</div>
                        <div className="table__header--field flex--center">Done</div>
                        <div className="table__header--field flex--center"></div>
                      </div>
                      <div className="table__rows_container">
                        {
                          activities.map((activity, index) => {
                            let isOverdue = false
                            if (new Date(activity.planned_date) < now & !activity.done) {
                              isOverdue = true
                            }

                            return (
                              <div key={index}>
                                <div className={`table__row-9 ${isOverdue ? "overdue" : activity.done ? "done" : ""}`} id='checkmark-row' data-activity-id={activity.id} onClick={handleClickRow}>
                                  <TableCheckmarkRow
                                    index={index}
                                    id={`table-checkmark-row-activities-${index}`}
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                  />                            
                                  <div className="table__row--field flex--center"><img src={activity_types[activity.type]?.picto} /></div>
                                  <div className="table__row--field">{activity.customer_id.business_name}</div>
                                  <div className="table__row--field">{activity.title}</div>
                                  <div className="table__row--field">{activity.sales_rep_id.first_name} {activity.sales_rep_id.last_name}</div>
                                  <div className="table__row--field">{formatDate(activity.planned_date)}</div>
                                  <div className="table__row--field flex--center">{activity.priority}</div>
                                  <div className="table__row--field flex--center">
                                    <CheckBox
                                      index={activity.id}
                                      state={activity.done}
                                      width={"20px"}
                                      height={"20px"}
                                      margin={"0"}
                                      setData={handlePreCheckDone}
                                      style={"hybrid"}
                                      type={"controlled"}
                                    />
                                  </div>
                                  <div className="table__row--field flex--center" onClick={(e) => handleEditActivity(e, activity.id)}><EditIcon /></div>
                                </div>
                                <div className="activity__detail hide">
                                  <div className={`activity__detail--comment ${activity.comment == "" ? "hide" : ""}`}>
                                    <h3>Comment</h3>
                                    {activity.comment}
                                  </div>
                                  {
                                    activity.done &&
                                    activity.report.map((report, index) => (
                                      <div key={index}>
                                        <h3>Report for {report.brandowner_id.business_name}</h3>
                                        {report.report}
                                      </div>
                                    ))
                                  }
                                  {activity.type === "Email" && activity.email_log && (
                                    <div className="activity__detail--email">
                                      <div
                                        className="email-icon"
                                        onClick={(e) => downloadEmailFile(activity.email_log.email_file_url)}
                                      >
                                        <MdOutlineMarkEmailUnread size={24} />
                                        <span>Download Email</span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
              }

            </div>

          }

        </div>
      </div>
      <NotificationPopup />
    </div>

  )
}

export default CustomerInfoPage