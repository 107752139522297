import { persistStore } from 'redux-persist'
import { useDispatch, useSelector } from 'react-redux'
import httpClient from '../../httpClient'
import { store } from '../../store/store'
import { showNotificationPopup } from "../NotificationPopup"
import { showNotificationPopupMobile } from "../mobile/NotificationPopupMobile"
import { resetAppState } from '../../store/store'
import { setAppVersion } from '../../store/global'

let isChecking = false

export function useVersionCheck() {
  const dispatch = useDispatch()
  const isMobile = useSelector(state => state.global.isMobile)

  const checkForUpdate = async () => {
    if (isChecking) {
      return false
    }

    isChecking = true

    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/check-version`)
      if (response.status !== 200) {
        console.log('Error checking version')
        isChecking = false
        return false
      }

      const APP_VERSION = response.data.version
      dispatch(setAppVersion(APP_VERSION))
      console.log('App version set to', APP_VERSION)

      const currentVersion = localStorage.getItem('app:version:stoqup')

      if (!currentVersion || currentVersion !== APP_VERSION) {
        // Version mismatch or not present, handle update
        if (currentVersion) {
          if (isMobile) {
            showNotificationPopupMobile({
              title: "Update available 🚀",
              message: "There is a new version of the app available. Please update to the new version.",
              actionButtonOnClick: () => {
                handleUpdate()
                localStorage.setItem('app:version:stoqup', APP_VERSION)
                console.log('App updated to version', APP_VERSION)
              },
              actionButtonText: "Update",
            })
          } else {
            showNotificationPopup({
              title: "Update available 🚀",
              message: "There is a new version of the app available. Please update to the new version.",
              actionButtonOnClick: () => {
                handleUpdate()
                localStorage.setItem('app:version:stoqup', APP_VERSION)
                console.log('App updated to version', APP_VERSION)
              },
              actionButtonText: "Update",
            })
          }
        } else {
          // If it's a fresh install (no previous version), set the current version
          localStorage.setItem('app:version:stoqup', APP_VERSION)
        }

        isChecking = false
        return true // Indicates an update is available
      } else {
        console.log('App version is current')
        isChecking = false
        return false // Indicates no update
      }
    } catch (error) {
      console.error('Error checking for update:', error)
      isChecking = false
      return false
    }
  }

  return checkForUpdate
}

export async function purgePersist() {
  const persistor = persistStore(store)
  persistor.purge().then(() => {
    console.log('Persisted state has been purged.')
  }).catch(err => {
    console.error('Failed to purge persisted state:', err)
  })
}

export async function handleUpdate() {
  // Clear Redux state except user.userInfo
  resetAppState(true)

  // Clear IndexedDB
  await clearIndexedDB()

  // Purge persisted state
  await purgePersist()

  // Unregister service worker and clear caches
  if ('serviceWorker' in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations()
    for (const registration of registrations) {
      await registration.unregister()
    }
    
    // Clear all caches
    const cacheKeys = await caches.keys()
    await Promise.all(cacheKeys.map(key => caches.delete(key)))
  }

  // Force reload from server
  window.location.href = window.location.href
}

export async function clearIndexedDB() {
  return new Promise((resolve, reject) => {
    const databases = indexedDB.databases()
    databases.then((dbs) => {
      const deletePromises = dbs.map(({ name }) => 
        new Promise((res, rej) => {
          const request = indexedDB.deleteDatabase(name)
          request.onsuccess = res
          request.onerror = rej
        })
      )
      Promise.all(deletePromises).then(resolve).catch(reject)
    })
  })
}
